import Dexie from 'dexie';

import CleverPushApi from './api';
import request from './utils/request';
import { urlBase64ToUint8Array } from './utils/https';

const DB_NOTIFICATIONS_LIMIT = 100;

if (typeof cleverpushConfig === 'undefined') {
  self.cleverpushConfig = {
    apiEndpoint: 'https://api.cleverpush.com',
    channelId: 'HX5h5pAyCMix2hBZx' // demo.cleverpush.com
  };
}

self.db = new Dexie('cleverpush');

/*
self.changeDbSchema = (schemaChanges) => {
  self.db.close();
  const newDb = new Dexie(self.db.name);
  newDb.version(self.db.verno + 1).stores(schemaChanges);
  return newDb.open();
};

Dexie.exists(self.db.name).then((dbExists) => {
  if (!dbExists) {
    self.version(1).stores({
      notifications: 'id,url,clicked,title,text,icon,subscriptionId,channelId,autoHide'
    });
  }

  self.db.open().then(() => {
    self.changeDbSchema({
      notifications: 'id,url,clicked,title,text,icon,subscriptionId,channelId,autoHide',
      subscription: 'id,channelId'
    });
  });
});
*/

self.initDb = () => {
  // console.info('CleverPush worker: initDb');

  self.db = new Dexie('cleverpush');
  self.db.version(1).stores({
    notifications: 'id,url,clicked,title,text,icon,subscriptionId,channelId,autoHide'
  });
  self.db.version(2).stores({
    notifications: 'id,url,clicked,title,text,icon,subscriptionId,channelId,autoHide',
    subscription: 'id,channelId'
  });
  self.db.version(3).stores({
    notifications: 'id,url,clicked,title,text,icon,subscriptionId,channelId,autoHide,markedAsDelivered,markedAsClicked,clickedAction',
    subscription: 'id,channelId'
  });
  self.db.version(4).stores({
    notifications: 'id,url,clicked,title,text,icon,subscriptionId,channelId,autoHide,markedAsDelivered,markedAsClicked,clickedAction,deliveredAt',
    subscription: 'id,channelId'
  });

  self.db.on('versionchange', (e) => {
    console.log('[CleverPush][WORKER] DB versionchange', e);
    if (e.newVersion === null) {
      e.target.close();
    } else {
      self.db.close();
      self.db.open();
      return false;
    }
  });

  self.db.open().catch((e) => {
    console.log('[CleverPush][WORKER] DB open err', e);
    if (e.name === 'UpgradeError' || e.name === 'VersionError' || (e.message && e.message.toString().indexOf('VersionError') > -1)) {
      // console.log('[CleverPush][WORKER] CleverPush worker: recreating db');
      const req = indexedDB.deleteDatabase('cleverpush');
      req.onsuccess = () => {
        self.initDb();
        self.db.open().catch((e) => {
          // console.warn(e);
        });
      };
      req.onabort = (err) => {

      };
      req.onerror = (err) => {
        console.log('[CleverPush][WORKER] CleverPush worker: error recreating db', err);
      };
    } else {
      console.log('[CleverPush][WORKER] CleverPush worker: error opening db', e);
    }
  });
};

try {
  self.initDb();
} catch (err) {
  console.log('[CleverPush][WORKER] CleverPush worker: initDb err', err);
}

self.api = new CleverPushApi(cleverpushConfig.channelId);

self.syncingSubscription = false;
self.syncSubscription = (subscriptionId, channelIdParam, browserSubscriptionParam, extraParams) => {
  const channelId = typeof channelIdParam !== 'undefined' ? channelIdParam : cleverpushConfig.channelId;

  console.info('[CleverPush][WORKER] syncSubscription', subscriptionId, channelId);

  const getBrowserSubscription = () => {
    return new Promise((resolve) => {
      if (typeof browserSubscriptionParam !== 'undefined') {
        resolve(browserSubscriptionParam);
      } else {
        self.registration.pushManager.getSubscription().then((browserSubscription) => {
          resolve(browserSubscription);
        });
      }
    });
  };

  return getBrowserSubscription().then((browserSubscription) => {
    return self.api.getSyncParameters().then((paramsParam) => {
      const params = Object.assign({
        endpoint: browserSubscription.endpoint,
        subscriptionId,
        channelId,
        workerVersion: typeof VERSION !== 'undefined' ? VERSION : undefined
      }, paramsParam, extraParams || {});
      const browserVersionMain = parseInt((params.browserVersion || '0').split('.')[0], 10);

      if (typeof browserSubscription.getKey !== 'undefined') {
        const key = browserSubscription.getKey ? browserSubscription.getKey('p256dh') : null;
        let auth = null;
        if (params.browserType !== 'Firefox' || (params.browserType === 'Firefox' && browserVersionMain >= 46)) {
          auth = browserSubscription.getKey ? browserSubscription.getKey('auth') : null;
        }
        if (key) {
          params.publicKey = btoa(String.fromCharCode.apply(null, new Uint8Array(key)));
        }
        if (auth) {
          params.authSecret = btoa(String.fromCharCode.apply(null, new Uint8Array(auth)));
        }
      }

      return request(cleverpushConfig.apiEndpoint + '/subscription/sync/' + channelId, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(params)
      }).then(res => res.id);
    });
  });
};

self.simulateEvent = (eventName) => {
  self.dispatchEvent(new ExtendableEvent(eventName));
};

function limitDBNotificationsToNewest() {
  if (!self.db?.notifications) {
    console.log('[CleverPush][WORKER] limitDBNotificationsToNewest no db');
    return Promise.resolve();
  }

  return self.db.notifications.orderBy('deliveredAt').offset(DB_NOTIFICATIONS_LIMIT).delete()
    .catch((err) => {
      console.error('[CleverPush][WORKER] limitDBNotificationsToNewest failed', err);
    });
}

self.markAsDelivered = (notification, channel, subscription, retry) => {
  // eslint-disable-next-line arrow-body-style
  const addToDatabase = () => {
    return new Promise((resolve) => {
      try {
        if (!cleverpushConfig?.saveDeliveredWebNotificationsIntoBrowserDB) {
          resolve();
          return;
        }

        if (!self.db || !self.db.notifications) {
          resolve();
          return;
        }

        limitDBNotificationsToNewest();

        self.db.notifications.put({
          id: notification._id,
          url: notification.url,
          title: notification.title,
          text: notification.text,
          icon: notification.iconUrl && notification.iconUrl.length ? notification.iconUrl : channel.iconUrl,
          media: notification.mediaUrl,
          autoHide: notification.autoHide,
          subscriptionId: subscription._id,
          channelId: channel._id,
          createdAt: notification.createdAt,
          expiresAt: notification.expiresAt,
          deliveredAt: new Date(),
          clicked: 0,
          markedAsDelivered: 0
        }).then(resolve).catch((err) => {
          console.error('[CleverPush][WORKER] markAsDelivered addToDatabase add failed', notification, err);
          resolve();
          // Raven.captureException(err);
        });
      } catch (err) {
        console.error('[CleverPush][WORKER] markAsDelivered addToDatabase add failed', notification, err);
        resolve();
      }
    });
  };

  if (notification._id === 'welcomeNotification' || notification._id === 'welcome') {
    if (cleverpushConfig?.saveDeliveredWebNotificationsIntoBrowserDB) {
      return addToDatabase();
    }

    return Promise.resolve();
  }

  const data = {
    notificationId: notification._id,
    subscriptionId: subscription._id,
    channelId: channel._id,
    workerVersion: typeof VERSION !== 'undefined' ? VERSION : undefined
  };

  if (typeof retry !== 'undefined' && retry) {
    data.retry = true;
  }

  // console.log('[CleverPush][WORKER] -> markAsDelivered request', data);

  return addToDatabase().then(() => {
    return request(cleverpushConfig.apiEndpoint + '/notification/delivered', {
      method: 'POST',
      redirect: 'follow',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    });
  });

  /*
  .then(() => {
    return addToDatabase(1);
  }).catch((err) => {
    console.error('markAsDelivered fetch request failed', notification, err.reason, err);
    // Raven.captureException(err);

    if (data.retry) {
      return self.db.notifications.update(notification._id, { markedAsDelivered: 2 }).catch((transactionErr) => { // 2: retry failed
        console.error('markAsDelivered transaction after fetch fail failed', notification, transactionErr);
        // Raven.captureException(err);
      });
    } else {
      return addToDatabase(0);
    }
  });
  */
};

self.markingPendingAsDelivered = false;
self.markPendingAsDelivered = (ignoreNotificationId) => {
  return Promise.resolve();

  if (self.markingPendingAsDelivered) {
    return Promise.resolve();
  }
  self.markingPendingAsDelivered = true;

  // console.log('[CleverPush][WORKER] -> markPendingAsDelivered');

  return self.db.notifications.where('markedAsDelivered').equals(0).toArray().then((notifications) => {
    // console.log('[CleverPush][WORKER] -> markPendingAsDelivered notifications', notifications);

    const notificationPromiseFns = [];
    notifications.forEach((unmarkedDbNotification) => {
      // deliveredAt needs to be at least 1h in the past
      if (unmarkedDbNotification.deliveredAt && unmarkedDbNotification.deliveredAt.getTime && (unmarkedDbNotification.deliveredAt.getTime() + (1000 * 60 * 60)) > Date.now()) {
        return Promise.resolve();
      }

      if (typeof ignoreNotificationId !== 'undefined' && unmarkedDbNotification && unmarkedDbNotification.id === ignoreNotificationId) {
        return Promise.resolve();
      }

      notificationPromiseFns.push(((notif) => {
        return self.markAsDelivered({ _id: notif.id }, { _id: notif.channelId }, { _id: notif.subscriptionId }, true);
      }).bind(null, unmarkedDbNotification));
    });

    return notificationPromiseFns.reduce((p, fn) => {
      return p = p.then(fn);
    }, Promise.resolve()).then(() => {
      self.markingPendingAsDelivered = false;
    });
  });
};

self.markAsClicked = (dbNotification, retry) => {
  const updateDatabase = () => {
    return new Promise((resolve) => {
      resolve();

      /*
      if (!self.db || !self.db.notifications) {
        resolve();
        return;
      }

      const update = { clicked: 1, markedAsClicked: 0 };
      if (!isNaN(dbNotification.clickedAction)) {
        update.clickedAction = dbNotification.clickedAction;
      }

      self.db.notifications.update(dbNotification.id, update).then(resolve).catch((err) => {
        console.error('markedAsClicked transaction failed', dbNotification, err);
        resolve();
        // Raven.captureException(err);
      });
      */
    });
  };

  if (dbNotification.id === 'welcomeNotification') {
    return Promise.resolve();
  }

  const data = {
    notificationId: dbNotification.id,
    subscriptionId: dbNotification.subscriptionId,
    channelId: dbNotification.channelId,
    workerVersion: typeof VERSION !== 'undefined' ? VERSION : undefined
  };
  if (!isNaN(dbNotification.clickedAction)) {
    data.action = dbNotification.clickedAction;
  }
  if (dbNotification.clickedActionType) {
    data.clickedActionType = dbNotification.clickedActionType;
  }

  if (typeof retry !== 'undefined' && retry) {
    data.retry = true;
  }

  // console.log('[CleverPush][WORKER] -> markAsClicked request', data);

  return updateDatabase().then(() => {
    return request(cleverpushConfig.apiEndpoint + '/notification/clicked', {
      method: 'POST',
      redirect: 'follow',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    });
  });

  /*
  .then(() => {
    return self.db.notifications.update(dbNotification.id, { markedAsClicked: 1 }).catch((err) => {
      console.error('markedAsClicked transaction failed', dbNotification, err);
      // Raven.captureException(err);
    });
  }).catch((err) => {
    console.error('markedAsClicked fetch failed', dbNotification, err);
    // Raven.captureException(err);

    if (data.retry) {
      return self.db.notifications.update(dbNotification.id, { markedAsClicked: 2 }).catch((transactionErr) => {
        console.error('markedAsClicked transaction after fetch fail failed', dbNotification, transactionErr);
        // Raven.captureException(err);
      });
    } else {
      return Promise.resolve();
    }
  });
  */
};

self.markingPendingAsClicked = false;
self.markPendingAsClicked = (ignoreNotificationId) => {
  // return Promise.resolve();

  if (self.markingPendingAsClicked) {
    return Promise.resolve();
  }
  self.markingPendingAsClicked = true;

  // console.log('[CleverPush][WORKER] -> markPendingAsClicked');

  return self.db.notifications.where('markedAsClicked').equals(0).toArray().then((notifications) => {
    // console.log('[CleverPush][WORKER] -> markPendingAsClicked notifications', notifications);

    const notificationPromiseFns = [];
    notifications.forEach((unmarkedDbNotification) => {
      // deliveredAt needs to be at least 1h in the past
      if (unmarkedDbNotification.deliveredAt && unmarkedDbNotification.deliveredAt.getTime && (unmarkedDbNotification.deliveredAt.getTime() + (1000 * 60 * 60)) > Date.now()) {
        return Promise.resolve();
      }

      if (typeof ignoreNotificationId !== 'undefined' && unmarkedDbNotification && unmarkedDbNotification.id === ignoreNotificationId) {
        return Promise.resolve();
      }

      notificationPromiseFns.push(((notif) => {
        return self.markAsClicked(notif, true);
      }).bind(null, unmarkedDbNotification));
    });

    return notificationPromiseFns.reduce((p, fn) => {
      return p = p.then(fn);
    }, Promise.resolve()).then(() => {
      self.markingPendingAsClicked = false;
    });
  });
};

self.showNotification = ({ notification, channel, subscription }) => {
  const params = {
    requireInteraction: !notification.autoHide,
    tag: notification.tag || notification._id,
    renotify: true,
    data: {
      id: notification._id,
      subscriptionId: subscription._id,
      channelId: channel._id,
      url: notification.url,
      notification,
      channel,
      subscription
    }
  };

  if (typeof notification.renotify !== 'undefined') {
    params.renotify = notification.renotify;
  }

  if (notification.text) {
    params.body = notification.text;
  }

  const icon = notification.iconUrl && notification.iconUrl.length ? notification.iconUrl : channel.iconUrl;
  if (icon) {
    params.icon = icon;
  }

  if (notification.mediaUrl && notification.mediaUrl.length) {
    params.image = notification.mediaUrl;
  }

  if (notification.badgeUrl && notification.badgeUrl.length) {
    params.badge = notification.badgeUrl;
  }

  if (notification.lang && notification.lang.length) {
    params.lang = notification.lang;
  }

  if (notification.dir && notification.dir.length) {
    params.dir = notification.dir;
  }

  if (notification.vibrate && notification.vibrate.length) {
    params.vibrate = notification.vibrate;
  }

  if (notification.actions && notification.actions.length) {
    params.actions = notification.actions.map((action, i) => {
      return { action: i, title: action.title, icon: action.icon };
    });
    params.data.actions = notification.actions;
  }

  if (notification.createdAt) {
    params.timestamp = (new Date(notification.createdAt)).getTime();
  }

  if (notification.expiresAt) {
    const seconds = Math.round(((new Date(notification.expiresAt)).getTime() - (new Date()).getTime()) / 1000);
    if (seconds < 20) {
      params.requireInteraction = false;

      try {
        setTimeout(() => {
          self.registration.getNotifications({ tag: notification._id }).then((notifications) => {
            notifications.forEach(notificationToClose => notificationToClose.close());
            return Promise.resolve();
          });
        }, 20 * 1000);
      } catch (err) {

      }
    }
  }

  return self.registration.showNotification(notification.title, params);
};

self.addEventListener('install', (event) => {
  event.waitUntil(self.skipWaiting());
});

self.addEventListener('activate', (event) => {
  event.waitUntil(self.clients.claim());
});

self.getPendingNotifications = () => {
  return self.registration.pushManager.getSubscription()
    .then((subscription) => {
      return request(cleverpushConfig.apiEndpoint + '/notifications/pending', {
        method: 'POST',
        redirect: 'follow',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          channelId: cleverpushConfig.channelId,
          subscriptionEndpoint: subscription.endpoint
        })
      }).then((data) => {
        if (data.error) {
          console.error(data.error);
          // Raven.captureException(data.error);
          // throw new Error(data.error);
        }

        return Promise.resolve({
          notifications: data.notifications || [],
          subscription: data.subscription,
          channel: data.channel
        });
      }).catch((err) => {
        console.error(err);
        // Raven.captureException(err);
      });
    });
};

self.parseOrFetchNotifications = (event) => {
  if (event.data) {
    const json = event.data.json();
    return Promise.resolve({
      notifications: [json.notification],
      channel: json.channel,
      subscription: json.subscription
    });
  }
  return self.getPendingNotifications();
};

self.showBackupNotification = () => {
  if (!self.db || !self.db.notifications) {
    return Promise.resolve();
  }

  return self.db.notifications.toArray().then((notificationsArray) => {
    return Promise.resolve(notificationsArray.filter(notification => notification.createdAt).sort((a, b) => {
      return new Date(b.createdAt) - new Date(a.createdAt);
    }));
  }).then((sortedNotifications) => {
    if (sortedNotifications && sortedNotifications.length) {
      return self.registration.showNotification(sortedNotifications[0].title);
    }
    return Promise.resolve();
  });
};

self.addEventListener('push', (event) => {
  event.waitUntil(
    self.parseOrFetchNotifications(event).then(({ notifications, subscription, channel }) => {
      /*
      if (!notifications || notifications.length === 0) {
        return self.showBackupNotification();
      }
      */

      const notificationEventPromiseFns = [];

      for (let notification of notifications) {
        // never nest the following line in a callback from the point of entering from retrieveNotifications
        notificationEventPromiseFns.push(((notif) => {
          return self.showNotification({ notification: notif, channel, subscription })
            .then(() => {
              return self.markAsDelivered(notification, channel, subscription).catch((err) => {
                console.error('CleverPush: markAsDelivered failed', err);
              });
              /*
              .then(() => {
                return self.markPendingAsDelivered(notification._id);
              }).catch((err) => {
                console.error('markPendingAsDelivered failed', err);
              });
              */
            });
        }).bind(null, notification));
      }

      return notificationEventPromiseFns.reduce((p, fn) => {
        return p = p.then(fn);
      }, Promise.resolve());

    }).catch(e => {
      console.error('CleverPush: failed to show notification:', e);
      // return self.showBackupNotification();
    })
  );
});

self.addEventListener('pushsubscriptionchange', (event) => {
  console.log('[CleverPush][WORKER] pushsubscriptionchange', event);

  const subscribeOpts = { userVisibleOnly: true };
  if (cleverpushConfig.vapidPublicKey && cleverpushConfig.vapidPublicKey.length) {
    subscribeOpts.applicationServerKey = urlBase64ToUint8Array(cleverpushConfig.vapidPublicKey);
  }

  event.waitUntil(
    self.registration.pushManager.subscribe(subscribeOpts).then((browserSubscription) => {
      return self.db.subscription.toCollection().first().then((subscriptionData) => {
        return self.syncSubscription(subscriptionData.id, subscriptionData.channelId, browserSubscription, {});
      });
    })
  );
});

self.onNotificationClick = (event) => {
  // console.log('[CleverPush][WORKER] -> onNotificationClick', event);

  event.notification.close();

  /*
  const getNotificationData = () => {
    console.log('[CleverPush][WORKER] -> getNotificationData', event.notification.tag);

    if (event.notification.data) {
      return Promise.resolve(event.notification.data);
    }
    console.warn('notification data is undefined', event.notification);
    return self.db.notifications.get(event.notification.tag).then((dbNotification) => {
      return Promise.resolve(dbNotification || {});
    });
  };
  */

  const notificationData = event.notification.data;

  if (notificationData) {
    const notificationId = notificationData.id || event.notification.tag;

    const clickedAction = parseInt(event.action, 10);
    let url = notificationData.url;
    let action;
    if (!isNaN(clickedAction) && notificationData.actions && notificationData.actions[clickedAction]) {
      action = notificationData.actions[clickedAction];
      if (action.type === 'close' || action.type === 'snooze') {
        url = null;
      } else if (action.url) {
        url = notificationData.actions[clickedAction].url;
      }
    }

    const openUrl = (url) => {
      return clients.matchAll({
        type: 'window',
        includeUncontrolled: true
      }).then((clientList) => {
        for (let i = 0; i < clientList.length; i += 1) {
          const client = clientList[i];
          if (!client.frameType || client.frameType !== 'nested') {
            if (url && client.url === url && 'focus' in client) {
              return client.focus().catch((err) => {
                console.error(`CleverPush: openUrl: failed to focus client ${url}`, err);
              });
            }
          }
        }

        if (url && clients.openWindow) {
          return clients.openWindow(url).catch((err) => {
            console.error(`CleverPush: openUrl: failed to open URL ${url}`, err);
          });
        } else {
          console.warn('CleverPush: no URL given');
        }

        return Promise.resolve();

      }).catch((err) => {
        console.error(`CleverPush: openUrl: failed to match clients ${url}`, err);

        if (url && clients.openWindow) {
          return clients.openWindow(url).catch((err) => {
            console.error(`CleverPush: openUrl: failed to open URL ${url}`, err);
          });
        } else {
          console.warn('CleverPush: no URL given');
        }

        return Promise.resolve();
      });
    };

    return openUrl(url).then(() => {
      if (notificationId) {
        const markAsClickedData = {
          id: notificationId,
          subscriptionId: notificationData.subscriptionId,
          channelId: notificationData.channelId
        };
        if (!isNaN(clickedAction)) {
          markAsClickedData.clickedAction = clickedAction;
        }
        if (action && action.type) {
          markAsClickedData.clickedActionType = action.type;
        }

        // keepOpenOnActionClicks feature
        try {
          if (action && notificationData.notification && notificationData.notification.keepOpenOnActionClicks) {
            self.showNotification({
              notification: {
                tag: notificationData.notification.tag,
                _id: notificationData.notification._id,
                title: notificationData.notification.title,
                text: notificationData.notification.text,
                url: notificationData.notification.url,
                channel: notificationData.notification.channel,
                iconUrl: notificationData.notification.iconUrl,
                autoHide: notificationData.notification.autoHide,
                mediaUrl: notificationData.notification.mediaUrl,
                createdAt: notificationData.notification.createdAt,
                expiresAt: notificationData.notification.expiresAt
              },
              subscription: notificationData.subscription,
              channel: notificationData.channel
            });
          }
        } catch (err) {
          console.error('CleverPush: markedAsClicked keepOpenOnActionClicks', err);
        }

        return self.markAsClicked(markAsClickedData).catch((err) => {
          console.error('CleverPush: markAsClicked failed', err);
        });
      } else {
        console.error('CleverPush: markAsClicked: notificationId is undefined');
        return Promise.resolve();
      }
    });
  } else {
    console.error('CleverPush: notification data undefined');
    return Promise.resolve();
  }
};
self.addEventListener('notificationclick', event => event.waitUntil(self.onNotificationClick(event)));

/*
self.addEventListener('notificationclose', () => {

});
*/

/** @enum {string} */
const WorkerMessengerCommand = {
  /*
    Used to request the current subscription state.
   */
  AMP_SUBSCRIPTION_STATE: 'amp-web-push-subscription-state',
  /*
    Used to request the service worker to subscribe the user to push.
    Notification permissions are already granted at this point.
   */
  AMP_SUBSCRIBE: 'amp-web-push-subscribe',
  /*
    Used to unsusbcribe the user from push.
   */
  AMP_UNSUBSCRIBE: 'amp-web-push-unsubscribe',
  /*
    Returns Subscription ID and Config
   */
  AMP_CONFIG: 'amp-web-push-config',

  UPDATE: 'update',
  UNSUBSCRIBE: 'unsubscribe',
  SET_SUBSCRIPTION_DATA: 'setSubscriptionData'
};

/**
 Sends a postMessage() to all window frames the service worker controls.
 */
const broadcastReply = (command, payload) => {
  self.clients.matchAll()
    .then((clients) => {
      for (let i = 0; i < clients.length; i += 1) {
        const client = clients[i];
        client.postMessage({
          command,
          payload,
        });
      }
    });
};

self.addEventListener('message', (event) => {
  /*
    Messages sent from amp-web-push have the format:
    - command: A string describing the message topic (e.g.
      'amp-web-push-subscribe')
    - payload: An optional JavaScript object containing extra data relevant to
      the command.
   */

  console.log('[CleverPush][WORKER] CleverPush worker: received message', event.data);

  const { command } = event.data;

  if (command) {
    switch (command) {
      case WorkerMessengerCommand.AMP_CONFIG:
        const result = {};

        return Promise.all([
          self.db.subscription.toCollection().first().then((e) => {
            result.config = cleverpushConfig;
            result.subscriptionId = e && e.id;
            return Promise.resolve();
          }),
          clients.matchAll({
            type: 'window',
            includeUncontrolled: true
          }).then((clientList) => {
            let url;
            for (let i = 0; i < clientList.length; i += 1) {
              const client = clientList[i];
              if (client.url && client.url.indexOf('cleverpush-amp-permission-dialog.html') > -1) {
                continue;
              }
              if (!client.frameType || client.frameType !== 'nested') {
                url = client.url;
                break;
              }
            }
            result.url = url;
            return Promise.resolve();
          }),
        ]).then(() => {
          broadcastReply(WorkerMessengerCommand.AMP_CONFIG, result)
        });

      case WorkerMessengerCommand.AMP_SUBSCRIPTION_STATE:
        let retrievedPushSubscription = null;
        return self.registration.pushManager.getSubscription()
          .then((pushSubscription) => {
            retrievedPushSubscription = pushSubscription;
            if (!pushSubscription) {
              return null;
            }
            return self.registration.pushManager.permissionState(
              pushSubscription.options
            );
          }).then((permissionStateOrNull) => {
            if (permissionStateOrNull === null) {
              broadcastReply(WorkerMessengerCommand.AMP_SUBSCRIPTION_STATE, false);
            } else {
              const isSubscribed = !!retrievedPushSubscription && permissionStateOrNull === 'granted';
              broadcastReply(WorkerMessengerCommand.AMP_SUBSCRIPTION_STATE, isSubscribed);
            }
            return Promise.resolve();
          });

      case WorkerMessengerCommand.AMP_SUBSCRIBE:
        const subscribeOpts = { userVisibleOnly: true };
        if (cleverpushConfig.vapidPublicKey && cleverpushConfig.vapidPublicKey.length) {
          subscribeOpts.applicationServerKey = urlBase64ToUint8Array(cleverpushConfig.vapidPublicKey);
        }
        return self.registration.pushManager.subscribe(subscribeOpts).then((browserSubscription) => {
          return self.syncSubscription(undefined, cleverpushConfig.channelId, browserSubscription, {
            optInSource: 'amp'
          }).then((subscriptionId) => {
            self.db.subscription.clear().then(() => {
              return self.db.subscription.put({ id: subscriptionId, channelId: cleverpushConfig.channelId }).then(() => {
                broadcastReply(WorkerMessengerCommand.AMP_SUBSCRIBE, null);
              });
            }).catch((e) => {
              console.log('[CleverPush][WORKER] db.subscription operation failed', e);
              broadcastReply(WorkerMessengerCommand.AMP_SUBSCRIBE, null);
            });
            return Promise.resolve(subscriptionId);
          });
        });

      case WorkerMessengerCommand.AMP_UNSUBSCRIBE:
        return self.registration.pushManager.getSubscription().then((pushSubscription) => {
          return pushSubscription.unsubscribe().then(() => {
            self.db.subscription.toCollection().first().then((subscriptionData) => {
              self.api.unsubscribe(subscriptionData.id).then(() => {
                self.db.subscription.clear().catch((e) => {
                  console.log('[CleverPush][WORKER] db.subscription operation failed', e);
                });
              });
            });
            broadcastReply(WorkerMessengerCommand.AMP_UNSUBSCRIBE, null);
            return Promise.resolve();
          });
        });

      case WorkerMessengerCommand.UPDATE:
        return self.registration.update();

      case WorkerMessengerCommand.UNSUBSCRIBE:
        return self.registration.pushManager.getSubscription().then((pushSubscription) => {
          pushSubscription.unsubscribe();
        });

      case WorkerMessengerCommand.SET_SUBSCRIPTION_DATA:
        return self.db.subscription.clear().then(() => {
          return self.db.subscription.add({
            id: event.data.subscription._id,
            channelId: event.data.subscription.channel
          });
        });

      default:
        console.error('CleverPush: unknown message');
    }
  }

  return Promise.resolve();
});
